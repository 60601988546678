//Components
import TwoColumn from "../components/TwoColumn";
import Footer from "../components/Footer";
import QuickNav from "../components/QuickNav";
import Banner from "../components/Hero";

//Banner
import BannerImage from "../assets/images/OneFifty_Building_v2/Buildings-Hero.jpg"
// import MobileImage from "../assets/images/OneFifty_Building_v2/Mobile-image.jpg"

//Courtyard
import sec1Image1 from "../assets/images/OneFifty_Building_v2/section_1_image_1.png";
import sec1Image2 from "../assets/images/OneFifty_Building_v2/section_1_image_2.jpg";
import sec1Image3 from "../assets/images/OneFifty_Building_v2/section_1_image_3.png";

//Rooftop Screening Area
import sec2Image1 from "../assets/images/OneFifty_Building_v2/section_2_image_1.png";
import sec2Image2 from "../assets/images/OneFifty_Building_v2/section_2_image_2.png";
import sec2Image3 from "../assets/images/OneFifty_Building_v2/section_2_image_3.png";

//Business Centre
import sec3Image1 from "../assets/images/OneFifty_Building_v2/section_3_image_1.png";
import sec3Image2 from "../assets/images/OneFifty_Building_v2/section_3_image_2.jpg";
import sec3Image3 from "../assets/images/OneFifty_Building_v2/section_3_image_3.jpg";
import sec3Image4 from "../assets/images/OneFifty_Building_v2/section_3_image_4.jpg";

//Pet-spa
import sec4Image1 from "../assets/images/OneFifty_Building_v2/section_4_image_1.jpg";
import sec4Image2 from "../assets/images/OneFifty_Building_v2/section_4_image_2.png";

//Lounge with Fireplace
import sec5Image1 from "../assets/images/OneFifty_Building_v2/section_5_image_1.jpg";
import sec5Image2 from "../assets/images/OneFifty_Building_v2/section_5_image_2.jpg";
import sec5Image3 from "../assets/images/OneFifty_Building_v2/section_5_image_3.jpg";

//Billiard Room
import sec6Image1 from "../assets/images/OneFifty_Building_v2/section_6_image_1.jpg";
import sec6Image2 from "../assets/images/OneFifty_Building_v2/section_6_image_2.jpg";
import sec6Image3 from "../assets/images/OneFifty_Building_v2/section_6_image_3.jpg";

//Fitness Centre
import sec7Image1 from "../assets/images/OneFifty_Building_v2/section_7_image_1.jpg";
import sec7Image2 from "../assets/images/OneFifty_Building_v2/section_7_image_2.jpg";
import sec7Image3 from "../assets/images/OneFifty_Building_v2/section_7_image_3.jpg";

//Yoga Studio
import sec8Image1 from "../assets/images/OneFifty_Building_v2/section_8_image_1.png";

//Parking Garage    
import sec9Image1 from "../assets/images/OneFifty_Building_v2/section_9_image_1.jpg";
import sec9Image2 from "../assets/images/OneFifty_Building_v2/section_9_image_2.jpg";

//Mailroom
import sec10Image1 from "../assets/images/OneFifty_Building_v2/section_10_image_1.png";
import sec10Image2 from "../assets/images/OneFifty_Building_v2/section_10_image_2.png";

//Laundry Room
import sec11Image1 from "../assets/images/OneFifty_Building_v2/section_11_image_1.jpg";

//Bike Room
import sec12Image1 from "../assets/images/OneFifty_Building_v2/section_12_image_1.jpg";


//React Imports
import { Helmet } from "react-helmet";



let QuickLinks = {
    backgroundColor: "green",
    prev: {
        title: "Residences",
        url: "/residences"
    },
    next: {
        title: "Availabilities",
        url: "/availabilities"
    }
}
let footerData = {
    backgroundColor: "green"
}
let BannerData = {
    backgroundImage: BannerImage,
    mobileImage: BannerImage,
    backgroundColor: "normal",
    heading: "Change The Way You Live",
    description: "The amenities at One Fifty have been carefully designed to make your life better. A fitness center provides state-of-the-art equipment and a boutique-style yoga studio just steps from your front door. Curl up in front of the fireplace in the lounge or enjoy a movie under the stars on the rooftop’s outdoor screening area. This building offers more than just a place to rest your head – it’s filled with amenities designed to make your life more convenient, more efficient, and way more fun.",
    color : "off-white-brown",
}

const twoCoulmnData = [
    {
        index: "0",
        variant: "white-brown",
        heading: "Courtyard",
        description: "Step outside for a breath of fresh air in the Courtyard – an open-air gathering space with separate seating areas.",
        images: [sec1Image1, sec1Image2, sec1Image3],
        flexDirection: "row-reverse",
        hasPricing : false,
        padding: "20px 40px 30px",
        price: "",
        contentWidth: '700px',
    },
    {
        index: "1",
        variant: "brown",
        heading: "Rooftop Screening Area",
        description: "On warm summer nights, nothing beats an outdoor movie under the stars at the building’s Rooftop Screening Area",
        images: [sec2Image1, sec2Image2, sec2Image3],
        flexDirection: "row",
        hasPricing : false,
        padding: "20px 40px 30px",
        contentWidth: '700px',
    },
    {
        index: "2",
        variant: "white-orange",
        heading: "Business Centre",
        description: "Separate where you work from where you live by taking advantage of the on-site Business Center, which features high-speed internet access, comfortable seating areas, and a professional atmosphere.",
        images: [sec3Image1, sec3Image2, sec3Image3 ,sec3Image4],
        flexDirection: "row-reverse",
        hasPricing : false,
        padding: "40px 40px 60px",
        contentWidth: '700px',
    },
    {
        index: "3",
        variant: "brown",
        heading: "Pet Spa",
        description: "Separate where you work from where you live by taking advantage of the on-site Business Center, which features high-speed internet access, comfortable seating areas, and a professional atmosphere.",
        images: [sec4Image1,sec4Image2],
        flexDirection: "row",
        padding: "40px",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "4",
        variant: "green",
        heading: "Lounge with Fireplace",
        description: "The building’s communal Lounge is great for gathering with a group or simply stepping away from your main living space. On warm winter nights, curl up in front of the roaring fireplace.",
        images: [sec5Image1, sec5Image2, sec5Image3],
        flexDirection: "row-reverse",
        padding: "40px",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "5",
        variant: "white-brown",
        heading: "Billiard Room",
        description: "Perfect for gathering with friends or entertaining guests, the on-site Billiard Room is ready to serve as a stylish extension of your living space.",
        images: [sec6Image1, sec6Image2 ,sec6Image3],
        flexDirection: "row",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "6",
        variant: "brown",
        heading: "Fitness Center",
        description: "Achieve your fitness goals around your own schedule. The on-site Fitness Center offers brand new cardio machines and fitness equipment just steps from your front door.",
        images: [sec7Image1 ,sec7Image2 ,sec7Image3],
        flexDirection: "row-reverse",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "7",
        variant: "white-orange",
        heading: "Yoga Studio",
        description: "A boutique-style Yoga Studio allows you to find your center and create a well-rounded fitness routine without ever leaving home.",
        images: [sec8Image1],
        flexDirection: "row",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "8",
        variant: "brown",
        heading: "Parking Garage",
        description: "Say goodbye to circling the block in search of a parking spot. Our secure parking garage offers the convenience of designated spaces, ensuring your vehicle is always available when you need it. Enjoy the ease and peace of mind that comes with having your own reserved parking spot.",
        images: [sec9Image1 ,sec9Image2],
        flexDirection: "row-reverse",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "9",
        variant: "green",
        heading: "Mailroom",
        description: "All your packages and deliveries will be waiting for you in the building’s secure Mailroom.",
        images: [sec10Image1, sec10Image2],
        flexDirection: "row",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "10",
        variant: "white-brown",
        heading: "Laundry Room",
        description: "Every floor in One Fifty features its own personal Laundry Room, meaning you’ll have access to washers and dryers whenever you need them.",
        images: [sec11Image1],
        flexDirection: "row-reverse",
        hasPricing : false,
        contentWidth: '700px',
    },
    {
        index: "11",
        variant: "green",
        heading: "Bike Room",
        description: "Secure and store your bicycle with ease in our dedicated bike room. Equipped with ample racks and secure locking mechanisms, our facility ensures your bike is safe and accessible whenever you need it.",
        images: [sec12Image1],
        flexDirection: "row",
        hasPricing : false,
        contentWidth: '700px',
    },
]

export default function Building() {
    return (
        <>
            <Helmet>
                <title>Upscale Amenities | One Fifty Apartment Building in Bushwick - EXR</title>
                <meta name="description" content="Along with luxury Bushwick apartments, One Fifty offers upscale amenities like a fitness center, lounge, and rooftop screening area." />
            </Helmet>
            <Banner data={BannerData} />
            {
                twoCoulmnData.map((section, index) => {
                    return <TwoColumn key={index} data={section} />;
                })
            }
            <QuickNav links={QuickLinks} />
            <Footer data={footerData} />
        </>
    )
}